import React, { FC, useState } from 'react'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import { FormSectionStyle } from 'src/@legacy/style/styled/LP/FormSectionStyle.style'
import { WrapperLayout } from 'src/@legacy/@core/components/share/Wrapper/WrapperLayout'
import { SocialStyle } from 'src/@legacy/@core/style/styled/antd/Button.style'
import { RegisterStyle } from 'src/@legacy/@core/style/styled/RegisterStyle.style'
import { ANTDInputStyle } from 'src/@legacy/@core/style/base/antd-input-style'
import { Row, Col } from 'src/@legacy/@core/components/share/ANTD/Grid'
import { Form, useForm } from 'src/@legacy/@core/components/share/ANTD/Form'
import { PATTERN_VALIDATION_UNICODE } from 'src/@legacy/utilities/Constant'
import { Input } from 'src/@legacy/@core/components/share/ANTD/Input'
import { AppLink } from 'src/@legacy/@core/components/share/AppLink'
import { LINK_URL_TERMS, LINK_URL_POLICY, LINK_URL_TOKEN_NOT_FOUND, LINK_URL_COMPANY_JOB_LIST } from 'src/@legacy/utilities/LinkURL'
import { Button } from 'src/@legacy/@core/components/share/ANTD/Button'
import { TRACKING_ID_EMPLOYEER_REGISTER, TRACKING_ID_LOGIN_GOOGLE } from 'src/@legacy/utilities/TrackingIDConstant'
import { useAppRouter } from 'src/@legacy/@core/hooks/UseAppRouter'
import { useDimention } from 'src/@legacy/@core/hooks/UseDimention'
import { submitRegisterEmployerAPI } from 'src/@legacy/service/api/register.api'
import { handleErrorAPIFromRequest, LIST_URL } from '../../../@core/utilities/CatchApiError'
import { useStoreTokenAndFetchUser } from 'src/@legacy/@core/hooks/CurrentUserHooks'
import { toStringFromQuery } from 'src/@legacy/utilities/common'
import { readToken } from '../../../@core/utilities/JWTToken'
import { openNotificationWithIcon } from 'src/@legacy/@core/utilities/Notification'
import { useRecaptcha } from 'src/@legacy/@core/utilities/ReCaptcha'
import { useMixpanel } from '../../../utilities/Mixpanel'
import { verifyRecaptcha } from 'src/@legacy/@optimal-page/utillities/ReCaptcha'
import IconGoogle from '../assets/icon_google.svg'
import IconFacebook from '../assets/icon_facebook.svg'
import IconLinkedin from '../assets/icon_linkedin.svg'
import AsyncAction from 'src/@legacy/components/AsyncAction'
import { TRACKING_ID_CANDIDATE_REGISTER_FACEBOOK_BUTTON, TRACKING_ID_LOGIN_LINKEDIN } from 'src/@legacy/@core/utilities/TrackingIDConstant'
import Image from 'next/image'
import GoogleLogin from 'react-google-login'
import env from 'src/@legacy/utilities/env'
import { submitLoginWithSocialAPI } from 'src/@legacy/service/api/login.api'
import useLinkedinLogin from '../../login/hooks/useLinkedinLogin'
import { FacebookProvider, Login } from 'react-facebook-sdk'
import { getlinkedinCode } from 'src/utilities/SocialUtilsFunction'
import { Divider } from 'src/@legacy/@core/components/share/ANTD/Divider'
import withRegisterCompanyModal, { OpenRegisterProps } from '../../register/withRegisterCompanyModal'

const FormSection: FC<{ scrollRef } & OpenRegisterProps> = ({ scrollRef, openRegisterCompany }) => {
  const { translate, currentLanguageNumber } = useTranslation()
  const [form] = useForm()
  const [nonFieldError, setNoneFieldError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const storeTokenAndFetchUser = useStoreTokenAndFetchUser()

  const router = useAppRouter()
  const fallBackUrl = router.query.redirect
  const { loaded } = useRecaptcha()
  const { fromProduct } = useDimention()

  const Mixpanel = useMixpanel()

  const onFinish = (values) => {
    return verifyRecaptcha(values, (isNotGoogleBOT) => {
      if (isNotGoogleBOT) {
        setLoading(true)
        submitRegisterEmployerAPI({ values, fromProduct, currentLanguageNumber })
          .then((response) => {
            return storeTokenAndFetchUser(response.data.token).then(() => {
              const jwt = readToken(response.data.token)
              Mixpanel.alias(jwt.user_id.toString())
              Mixpanel.track(Mixpanel.EVENTS.SIGNUP)
              router.push(fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : LINK_URL_COMPANY_JOB_LIST(jwt.company_id))
            })
          })
          .catch((error) => {
            if (!error.response || (error.response && error.response.status === 422)) {
              if (
                error.response?.data?.messages === 'This email does not exist, please check again!' ||
                error.response?.data?.messages === 'Email này không tồn tại, vui lòng kiểm tra lại!'
              ) {
                setNoneFieldError(translate('notification:email_does_not_exist'))
                openNotificationWithIcon('error', translate('notification:email_does_not_exist'), '')
                return
              }
              if (error.response && error.response.data?.messages?.msgs[0] === 'Name has already been taken') {
                setNoneFieldError(translate('notification:name_exist'))
                openNotificationWithIcon('error', translate('notification:name_exist'), '')
              } else if (error.response && error.response.data?.messages?.msgs[0] === 'Email has already been taken') {
                setNoneFieldError(translate('notification:validate_email_unique'))
                openNotificationWithIcon('error', translate('notification:validate_email_unique'), '')
              }
              return
            } else {
              handleErrorAPIFromRequest({
                error,
                page: LIST_URL.REGISTER_CANDIDATE,
                callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription),
                callbackType: (type) => {
                  if (type === 'INVALID_TOKEN') {
                    router.push(LINK_URL_TOKEN_NOT_FOUND)
                  }
                }
              })
            }
            throw error
          })
          .finally(() => {
            setLoading(false)
          })
      }
    })
  }
  const [loadingGoogle, setLoadingGoogle] = useState(false)
  const socialLogin = (token, provider) => {
    setLoadingGoogle(true)
    // setOpen(!open)
    return submitLoginWithSocialAPI({ token, provider })
      .then((response) => {
        const jwt = readToken(response.data.token)
        // add tracking
        Mixpanel.identifyByToken(response.data.token)
        Mixpanel.trackLogin(Mixpanel.LoginMethod.SOCIAL)
        return storeTokenAndFetchUser(response.data.token).then(() =>
          router.push(fallBackUrl ? decodeURIComponent(toStringFromQuery(fallBackUrl)) : LINK_URL_COMPANY_JOB_LIST(jwt.company_id))
        )
      })
      .catch((error) => {
        if (error.response.data.messages.errors && error.response.data.messages.errors[0] === 'ACCOUNT_NOT_EXIST') {
          openRegisterCompany({
            payload: error.response.data.messages.payload,
            onError: (error) => {
              if (!error.response || (error.response && error.response.status === 422)) {
                if (error.response && error.response.data?.messages?.msgs[0] === 'Name has already been taken') {
                  setNoneFieldError(translate('notification:name_exist'))
                  openNotificationWithIcon('error', translate('notification:name_exist'), '')
                } else if (error.response && error.response.data?.messages?.msgs[0] === 'Email has already been taken') {
                  setNoneFieldError(translate('notification:validate_email_unique'))
                  openNotificationWithIcon('error', translate('notification:validate_email_unique'), '')
                }
                return true
              } else {
                handleErrorAPIFromRequest({
                  error,
                  page: LIST_URL.REGISTER_CANDIDATE,
                  callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription),
                  callbackType: (type) => {
                    if (type === 'INVALID_TOKEN') {
                      router.push(LINK_URL_TOKEN_NOT_FOUND)
                    }
                  }
                })
              }
            }
          })
        } else {
          handleErrorAPIFromRequest({
            error,
            page: LIST_URL.LOGIN_WITH_SOCIAL,
            callbackAction: (errorText, errorDescription) => openNotificationWithIcon('error', errorText, errorDescription)
          })
        }
      })

      .finally(() => setLoadingGoogle(false))
  }
  const responseGoogle = (response) => {
    if (response.accessToken) {
      return socialLogin(response.accessToken, 1)
    } else {
      return Promise.reject('cannot retrieve access token')
    }
  }

  const googleSocialErrorHandler = (err) => {}

  const { linkedInLogin: linkedInRegister } = useLinkedinLogin({
    onLoginCode: (code) => {
      return socialLogin(getlinkedinCode(code), 4)
    }
  })
  const loginFacebook = (data) => socialLogin(data?.tokenDetail?.accessToken, 0)
  /* ----------- FUNCTION HANDLER ----------- */
  return (
    <>
      <style jsx>{FormSectionStyle}</style>
      <style jsx global>
        {SocialStyle}
      </style>
      <style jsx>{RegisterStyle}</style>
      <style jsx global>
        {ANTDInputStyle}
      </style>
      <style jsx global>
        {`
          body .divider-login {
            color: #e8e8e8 !important;
          }
          body .divider-text {
            color: #7a7c82;
          }
        `}
      </style>
      <div className="form-section" id="last-call">
        <div ref={scrollRef}></div>
        <WrapperLayout className="wrapper-container">
          <Row
            gutter={[
              { xs: 0, lg: 102 },
              { xs: 24, md: 24, lg: 0 }
            ]}>
            <Col xs={24} lg={12} className="left">
              <h2 className="heading">{translate('page_employer_lp:form_section_heading')}</h2>
              <div className="step-wrapper">
                <div className="step">
                  <div className="step-number">01</div>
                  <div className="divider-step" />
                </div>
                <div className="content">
                  <h3 className="title-left">{translate('page_employer_lp:form_section_title_1')}</h3>
                  <div className="des-left">{translate('page_employer_lp:form_section_des_1')}</div>
                </div>
              </div>
              <div className="step-wrapper">
                <div className="step">
                  <div className="step-number">02</div>
                  <div className="divider-step" />
                </div>
                <div className="content">
                  <h3 className="title-left">{translate('page_employer_lp:form_section_title_2')}</h3>
                  <div className="des-left">{translate('page_employer_lp:form_section_des_2')}</div>
                </div>
              </div>
              <div className="step-wrapper">
                <div className="step">
                  <div className="step-number">03</div>
                </div>
                <div className="content">
                  <h3 className="title-left">{translate('page_employer_lp:form_section_title_3')}</h3>
                  <div className="des-left">{translate('page_employer_lp:form_section_des_3')}</div>
                </div>
              </div>
            </Col>
            <Col xs={24} lg={12} className="right">
              <div className="register-form">
                <div className="form-body">
                  <Form form={form} name="register_form" layout="vertical" onFinish={onFinish}>
                    <div className="title-right">{translate('page_employer_lp:form_section_heading_form')}</div>
                    <Row gutter={[16, 8]}>
                      <Col className="mb-2" xs={8} md={8} lg={8}>
                        <AsyncAction
                          action={linkedInRegister}
                          render={({ run, loading }) => {
                            return (
                              <Button
                                id={TRACKING_ID_LOGIN_LINKEDIN}
                                onClick={run}
                                disabled={loading}
                                loading={loading}
                                size="large"
                                className="btn-social">
                                <Image quality={100} src={IconLinkedin} alt="linkedin icon" width={24} height={24} />
                              </Button>
                            )
                          }}
                        />
                      </Col>
                      <Col xs={8} md={8} lg={8}>
                        <GoogleLogin
                          clientId={env.PUBLIC_GOOGLE_APP_ID}
                          render={(renderProps) => (
                            <Button
                              id={TRACKING_ID_LOGIN_GOOGLE}
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                              size="large"
                              className="btn-social">
                              <Image quality={100} src={IconGoogle} alt="google icon" width={24} height={24} />
                            </Button>
                          )}
                          buttonText="Login"
                          onSuccess={responseGoogle}
                          // onFailure={responseGoogle}
                          cookiePolicy={'single_host_origin'}
                        />
                      </Col>
                      <Col xs={8} md={8} lg={8}>
                        <FacebookProvider appId={env.PUBLIC_FACEBOOK_APP_ID}>
                          <Login
                            scope="email"
                            onResponse={loginFacebook}
                            render={({ isWorking, onClick }) => (
                              <Button
                                id={TRACKING_ID_CANDIDATE_REGISTER_FACEBOOK_BUTTON}
                                loading={isWorking}
                                className="btn-social"
                                size="large"
                                // disabled
                                onClick={onClick}>
                                <Image src={IconFacebook} alt="facebook icon" width={24} height={24} />
                              </Button>
                            )}
                          />
                        </FacebookProvider>
                      </Col>
                    </Row>
                    <Divider plain className="divider-login">
                      <span className="divider-text"> {translate('label:or_register_form_create_cv')}</span>
                    </Divider>
                    <Form.Item
                      className="mb-3"
                      label={translate('label:company_name')}
                      name="name"
                      rules={[
                        { required: true, message: translate('form:validation_company_name') },
                        {
                          min: 5,
                          message: translate('form:validate_range_length', {
                            min: 5,
                            max: 30
                          })
                        },
                        {
                          max: 30,
                          message: translate('form:validate_range_length', {
                            min: 5,
                            max: 30
                          })
                        },
                        () => ({
                          validator(rule, value) {
                            if (value) {
                              if (value.replace(/\s/g, '') === '') return Promise.reject(translate('form:validation_no_space'))
                            }
                            return Promise.resolve()
                          }
                        })
                      ]}>
                      <Input placeholder={translate('label:your_company_name')} size="large" />
                    </Form.Item>
                    <Form.Item
                      className="mb-3"
                      label={translate('label:full_name')}
                      name="full_name"
                      rules={[
                        { required: true, message: translate('form:validate_required') },
                        {
                          min: 5,
                          message: translate('form:validate_range_length', {
                            min: 5,
                            max: 100
                          })
                        },
                        {
                          max: 100,
                          message: translate('form:validate_range_length', {
                            min: 5,
                            max: 100
                          })
                        },
                        {
                          pattern: PATTERN_VALIDATION_UNICODE,
                          message: translate('form:validate_full_name_format')
                        }
                      ]}>
                      <Input placeholder={translate('label:your_full_name')} size="large" id="register_form_full_name" />
                    </Form.Item>
                    <Form.Item
                      className="mb-3"
                      label={translate('page_register:email_address')}
                      name="email"
                      rules={[
                        { required: true, message: translate('form:validate_required') },
                        { max: 250, message: translate('form:validate_max_length', { number: 250 }) },
                        {
                          type: 'email',
                          message: translate('form:validate_email_format')
                        }
                      ]}>
                      <Input placeholder="email@example.com" size="large" id="register_form_email" />
                    </Form.Item>
                    <Form.Item
                      className="mb-3"
                      label={translate('label:phone_number')}
                      name="phone"
                      rules={[
                        {
                          min: 9,
                          message: translate('form:validate_range_length', {
                            min: 9,
                            max: 15
                          })
                        },
                        {
                          max: 15,
                          message: translate('form:validate_range_length', {
                            min: 9,
                            max: 15
                          })
                        },
                        { pattern: /^[0-9]*$/, message: translate('form:validate_phone_format') }
                      ]}>
                      <Input size="large" prefix="+84" id="register_form_phone_number" />
                    </Form.Item>
                    <Form.Item
                      className="mb-3"
                      label={translate('label:password')}
                      name="password"
                      rules={[
                        { required: true, message: translate('form:validate_required') },
                        {
                          min: 6,
                          message: translate('form:validate_range_length', {
                            min: 6,
                            max: 20
                          })
                        },
                        {
                          max: 20,
                          message: translate('form:validate_range_length', {
                            min: 6,
                            max: 20
                          })
                        }
                      ]}>
                      <Input.Password size="large" placeholder={translate('label:placeholder_password')} id="register_form_password" />
                    </Form.Item>
                    <div className="error-text">{nonFieldError && <div>{nonFieldError}</div>}</div>
                    <Form.Item className="mb-3">
                      <div style={{ color: '#8c8c8c' }}>
                        {translate('page_register:agree_term_policy')}
                        <AppLink href={LINK_URL_TERMS} as={LINK_URL_TERMS}>
                          <a className="text-high-light" target="_blank">
                            {translate('label:terms')}
                          </a>
                        </AppLink>
                        {translate('page_register:and')}
                        <AppLink href={LINK_URL_POLICY} as={LINK_URL_POLICY}>
                          <a className="text-high-light" target="_blank">
                            {translate('label:privacy_policy')}
                          </a>
                        </AppLink>
                      </div>
                    </Form.Item>
                    <Form.Item className="mb-0">
                      <Button
                        loading={loading}
                        type="primary"
                        className="btn-submit w-100"
                        htmlType="submit"
                        size="large"
                        id={TRACKING_ID_EMPLOYEER_REGISTER}>
                        {translate('button:register_now_for_recruiter')}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </WrapperLayout>
      </div>
    </>
  )
}

export default withRegisterCompanyModal(FormSection)
